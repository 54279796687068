<template>
  <div>
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Reports
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/reports/type">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  @click="goBack"
                >
                  Reports
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  Parking Permit
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-card v-if="reportData && clientOptions && ($can('read', abilityRequired) || $can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-header>
        <h5>
          Report Details
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- User Info: Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- Field: Standard Hidden Fields -->
            <input
              v-model="reportData.report_type"
              type="hidden"
              name="report_type"
            >
            <input
              v-model="reportData.time_zone"
              type="hidden"
              name="time_zone"
            >
            <input
              v-model="reportData.client_display_name"
              type="hidden"
              name="client_display_name"
            >
            <input
              v-model="reportData.attachment_list"
              type="hidden"
              name="attachment_list"
            >
            <!-- Field: Permit Number -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="PERMIT #"
                  label-for="report_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="report_id"
                  >
                    <b-form-input
                      id="report_id"
                      v-model="reportData.report_id"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Client -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Client"
                  label-for="clientname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="clientname"
                  >
                    <b-form-input
                      id="clientname"
                      v-model="reportData.client_display_name"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Site selection -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Site"
                  label-for="sitename"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sitename"
                  >
                    <b-form-input
                      id="sitename"
                      v-model="reportData.site_address"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Suite -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Suite"
                  label-for="suite"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="suite"
                  >
                    <b-form-input
                      id="suite"
                      v-model="reportData.suite"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Email Address -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Email Address"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="reportData.email"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: License Plate # -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="License Plate #"
                  label-for="licence_plate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="licence_plate"
                  >
                    <b-form-input
                      id="licence_plate"
                      v-model="reportData.licence_plate"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Vehicle Make -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Vehicle Make"
                  label-for="vehicle_make"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vehicle_make"
                  >
                    <b-form-input
                      id="vehicle_make"
                      v-model="reportData.vehicle_make"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Vehicle Model -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Vehicle Model"
                  label-for="vehicle_model"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vehicle_model"
                  >
                    <b-form-input
                      id="vehicle_model"
                      v-model="reportData.vehicle_model"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: From Date/Time -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="From"
                  label-for="from_dt"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="from_dt"
                    rules="required"
                  >
                    <b-form-input
                      id="vehicle_model"
                      v-model="reportData.from_dt"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Expires Date/Time -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Expires"
                  label-for="expires_dt"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="expires_dt"
                    rules="required"
                  >
                    <b-form-input
                      id="vehicle_model"
                      v-model="reportData.expires_dt"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Description Text -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <ul>
                  <li>Please check this permit for accuracy.</li>
                  <li>Pass valid only for the times and dates indicated.</li>
                  <li>Incorrectly registered vehicles may be tagged and/or towed.</li>
                  <li>Residents are not permitted to park in visitors parking.</li>
                  <li>Visitors are permitted to park only in areas designated to visitors parking.</li>
                  <li>Visitors must comply with the declaration, bylaws and rules.</li>
                  <li>Vehicles parked in the visitors parking are left at owners risk.</li>
                  <li>The staff and agents of the corporation will not accept responsibility for loss or damage howsoever caused.</li>
                  <li>Permit is to be used by visitors and is not to be used by residents.</li>
                  <li>Permits must be clearly displayed at all times.</li>
                </ul>
                <strong>VEHICLES REMAINING AFTER PASS HAS EXPIRED MAY BE TOWED AT OWNERS EXPENSE</strong>
              </b-col>
            </b-row>
            <!-- Buttons -->
            <div
              v-if="reportID !== null"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="reportData && clientOptions && $can('update', abilityRequired) && reportData.username === userData.username"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Save Changes
                  </b-button>
                </b-col>
                <b-col
                  v-if="$can('read', abilityRequired)"
                  md="2"
                >
                  <!-- Print Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="printReport"
                  >
                    Print Report
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="$can('update', abilityRequired)"
                class="mt-4"
              >
                <b-col
                  v-if="!reportData.approved"
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Approve
                  </b-button>
                </b-col>
                <b-col
                  v-else
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Unapprove
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { required, email } from '@validations'
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { getUserData } from '@/auth/utils'
import reportsStoreModule from '../reportsStoreModule'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import ImageUploader from 'vue-image-upload-resize'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      preventDoubleClick: false,
    }
  },
  setup() {
    const userData = getUserData()
    const abilityRequired = 'reports'
    let theReportID = null
    let theSK = null
    let theReportType = null
    if (router.currentRoute.params.reportID !== undefined && router.currentRoute.params.reportID !== null
      && router.currentRoute.params.SK !== undefined && router.currentRoute.params.SK !== null
      && router.currentRoute.params.report_type !== undefined && router.currentRoute.params.report_type !== null) {
      theReportID = router.currentRoute.params.reportID
      theSK = router.currentRoute.params.SK
      theReportType = router.currentRoute.params.report_type
    }
    const reportID = ref(theReportID)
    const SK = ref(theSK)
    const reportType = ref(theReportType)
    const reportData = ref(null)
    const clientsData = ref([])
    // TODO: Get a list of clients available to the current user
    const clientOptions = ref([])
    const sitesData = ref([])
    const siteOptions = ref([])
    const resultOptions = store.state.appSettings.alarm_response_report_result_options
    const isSubscriberOptions = [{ text: 'Yes', value: 'Yes' }, { text: 'No', value: 'No' }]

    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportsStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    return {
      userData,
      abilityRequired,

      reportID,
      SK,
      reportType,
      reportData,
      clientsData,
      clientOptions,
      siteOptions,
      sitesData,
      resultOptions,
      isSubscriberOptions,
    }
  },
  created() {
    if (this.reportID === null) {
      this.reportData = {}
      this.reportData.report_type = 'alarm_response'
      this.reportData.time_zone = new Intl.DateTimeFormat().resolvedOptions().timeZone
      this.reportData.client_display_name = ''
      this.reportData.received_date = null
      this.reportData.attachment_list = []
      this.reportData.alarm_type_list = {}
      this.reportData.dispatch_request_type_list = {}
      this.reportData.report_to_client_list = {}
      this.reportData.sitename = ''
    } else {
      this.getExistingReport()
    }

    // watch(this.siteOptions, newValue => {
    //   console.log('siteOptions changed:', newValue)
    // }, { deep: true })

    // watch(() => this.reportData.sitename, newValue => {
    //   console.log('Selected sitename:', newValue)
    // })
  },
  methods: {
    goBack() {
      console.log('goback')
      window.history.back()
    },
    isNumeric(str) {
      if (typeof str !== 'string') return false // we only process strings!
      return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            && !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    getExistingReport() {
      console.log('getExistingReport')
      if (this.reportID !== null && this.SK !== null && this.reportType !== null && ability.can('read', this.abilityRequired)) {
        console.log('getting report data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Getting Report Data',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          reportID: this.reportID,
          SK: this.SK,
          reportType: this.reportType,
        }
        store.dispatch('app-reports/fetchReport', qvars)
          .then(response => {
            console.log(response)
            this.reportData = response.data
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Getting Report.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    validateForm() {
      console.log('validateForm')
      if (this.preventDoubleClick === false) {
        if (this.reportID === null) {
          if (ability.can('create', this.abilityRequired)) {
            console.log('adding report')
            let proceedWithSave = true
            // Manual Validation Items
            this.reportData.received_date = moment(this.reportData.received_date).format('YYYY-MM-DD HH:mm:ss')
            if (this.reportData.is_keys_used === undefined) {
              this.reportData.is_keys_used = false
            }
            if (this.reportData.keyNumBoxNum === undefined) {
              this.reportData.keyNumBoxNum = ''
            }
            if (this.reportData.alarm_type_list === undefined) {
              this.reportData.alarm_type_list = {}
            }
            if (this.reportData.alarm_type_list_other === undefined) {
              this.reportData.alarm_type_list_other = ''
            }
            if (this.reportData.dispatch_request_type_list === undefined) {
              this.reportData.dispatch_request_type_list = {}
            }
            if (this.reportData.alarm_zone === undefined) {
              this.reportData.alarm_zone = ''
            }
            if (this.reportData.is_service_dispatched === undefined) {
              this.reportData.is_service_dispatched = false
            }
            if (this.reportData.service_call_number === undefined) {
              this.reportData.service_call_number = ''
            }
            if (this.reportData.is_subscriber === undefined) {
              this.reportData.is_subscriber = false
            }
            if (this.reportData.subscriber_name === undefined) {
              this.reportData.subscriber_name = ''
            }
            if (this.reportData.fire_department === undefined) {
              this.reportData.fire_department = ''
            }
            if (this.reportData.police_department === undefined) {
              this.reportData.police_department = ''
            }
            if (this.reportData.other_names === undefined) {
              this.reportData.other_names = ''
            }
            if (this.reportData.report_to_client_list === undefined) {
              this.reportData.report_to_client_list = {}
            }
            proceedWithSave = true
            console.log(this.reportData)
            console.log(JSON.parse(JSON.stringify(this.reportData)))
            if (proceedWithSave) {
              this.$refs.observer.validate().then(success => {
                if (success) {
                  this.preventDoubleClick = true
                  console.log('form validated')
                  console.log(this.reportData)
                  // Create a clean dataset with only the data being updated
                  const cleanReportData = JSON.parse(JSON.stringify(this.reportData))
                  // delete cleanReportData.abilities
                  // delete cleanReportData.authorized_clients
                  console.log(cleanReportData)
                  // Call backend
                  store.dispatch('app-reports/addReport', cleanReportData)
                    .then(response => {
                      this.preventDoubleClick = false
                      console.log(response)
                      // TODO Need to set the reportID based on the response
                      this.reportID = response.report_id
                      this.reportData.report_id = response.report_id
                      this.reportData.SK = response.SK
                      this.reportData.approved = response.approved
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Report Added',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                    .catch(error => {
                      console.log(error)
                      this.preventDoubleClick = false
                      let theErrorStr = 'The following error(s) where received:\n'
                      if (typeof error.response.data.errors !== 'undefined') {
                        error.response.data.errors.forEach(item => {
                          theErrorStr += `${item.message}\n`
                        })
                      }
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Error Adding Report.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                          text: theErrorStr,
                        },
                      })
                    })
                }
              })
            }
          }
        } else if (ability.can('update', this.abilityRequired)) {
          console.log('updating report')
          let proceedWithSave = true
          // Manual Validation Items
          this.reportData.received_date = moment(this.reportData.received_date).format('YYYY-MM-DDTHH:mm:ss')
          if (this.reportData.is_keys_used === undefined) {
            this.reportData.is_keys_used = false
          }
          if (this.reportData.keyNumBoxNum === undefined) {
            this.reportData.keyNumBoxNum = 'false'
          }
          if (this.reportData.alarm_type_list === undefined) {
            this.reportData.alarm_type_list = {}
          }
          if (this.reportData.alarm_type_list_other === undefined) {
            this.reportData.alarm_type_list_other = ''
          }
          if (this.reportData.dispatch_request_type_list === undefined) {
            this.reportData.dispatch_request_type_list = {}
          }
          if (this.reportData.alarm_zone === undefined) {
            this.reportData.alarm_zone = ''
          }
          if (this.reportData.is_service_dispatched === undefined) {
            this.reportData.is_service_dispatched = 'false'
          }
          if (this.reportData.service_call_number === undefined) {
            this.reportData.service_call_number = 'false'
          }
          if (this.reportData.is_subscriber === undefined) {
            this.reportData.is_subscriber = 'false'
          }
          if (this.reportData.subscriber_name === undefined) {
            this.reportData.subscriber_name = ''
          }
          if (this.reportData.fire_department === undefined) {
            this.reportData.fire_department = ''
          }
          if (this.reportData.police_department === undefined) {
            this.reportData.police_department = ''
          }
          if (this.reportData.other_names === undefined) {
            this.reportData.other_names = ''
          }
          if (this.reportData.report_to_client_list === undefined) {
            this.reportData.report_to_client_list = {}
          }
          proceedWithSave = true
          console.log(this.reportData)
          console.log(JSON.parse(JSON.stringify(this.reportData)))
          if (proceedWithSave) {
            this.$refs.observer.validate().then(success => {
              if (success) {
                this.preventDoubleClick = true
                console.log('form validated')
                console.log(this.reportData)
                // Create a clean dataset with only the data being updated
                const cleanReportData = JSON.parse(JSON.stringify(this.reportData))
                // delete cleanReportData.abilities
                // delete cleanReportData.authorized_clients
                console.log(cleanReportData)
                // Call backend
                store.dispatch('app-reports/updateReport', cleanReportData)
                  .then(response => {
                    console.log(response)
                    this.preventDoubleClick = false
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Report Updated',
                        icon: 'CheckIcon',
                        variant: 'success',
                      },
                    })
                  })
                  .catch(error => {
                    console.log(error)
                    this.preventDoubleClick = false
                    let theErrorStr = 'The following error(s) where received:\n'
                    if (typeof error.response.data.errors !== 'undefined') {
                      error.response.data.errors.forEach(item => {
                        theErrorStr += `${item.message}\n`
                      })
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error Updating Report.',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: theErrorStr,
                      },
                    })
                  })
              }
            })
          }
        }
      }
    },
    async printReport() {
      const printContent = await this.generatePrintContent()
      console.log('printContent: ')
      console.log(printContent)
      const printWindow = window.open('', '_blank')
      printWindow.document.write(printContent)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(() => {
        printWindow.print()
        printWindow.close()
      }, 2000)
    },
    async generatePrintContent() {
      return `
      <html>
        <head>
          <title>Parking Permit</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            h2 {
              text-align: center;
            }
            .logo {
              width: 300px;
              margin-bottom: 20px;
            }
            .section {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 20px;
            }
            .section .label {
              width: 30%;
              font-weight: bold;
            }
            .section .value {
              width: 70%;
            }
            .section ul {
              list-style: none;
              padding: 0;
            }
            .section ul li {
              margin: 5px 0;
            }
          </style>
        </head>
        <body>
          <img src="/img/email-logo.png" class="logo" alt="Cancom">
          <h2>Parking Permit</h2>
          <div class="section">
            <div class="label">PERMIT NUMBER:</div>
            <div class="value">${this.reportData.report_id}</div>
          </div>
          <div class="section">
            <div class="label">Client:</div>
            <div class="value">${this.reportData.client_display_name}</div>
          </div>
          <div class="section">
            <div class="label">Site Address:</div>
            <div class="value">${this.reportData.site_address}</div>
          </div>
          <div class="section">
            <div class="label">Suite:</div>
            <div class="value">${this.reportData.suite}</div>
          </div>
          <div class="section">
            <div class="label">License Plate #:</div>
            <div class="value">${this.reportData.licence_plate}</div>
          </div>
          <div class="section">
            <div class="label">Vehicle Make:</div>
            <div class="value">${this.reportData.vehicle_make}</div>
          </div>
          <div class="section">
            <div class="label">Model:</div>
            <div class="value">${this.reportData.vehicle_model}</div>
          </div>
          <div class="section">
            <div class="label">From:</div>
            <div class="value">${this.reportData.from_dt}</div>
          </div>
          <div class="section">
            <div class="label">Expires:</div>
            <div class="value">${this.reportData.expires_dt}</div>
          </div>
          <ul>
            <li>Please check this permit for accuracy.</li>
            <li>Pass valid only for the times and dates indicated.</li>
            <li>Incorrectly registered vehicles may be tagged and/or towed.</li>
            <li>Residents are not permitted to park in visitors parking.</li>
            <li>Visitors are permitted to park only in areas designated to visitors parking.</li>
            <li>Visitors must comply with the declaration, bylaws and rules.</li>
            <li>Vehicles parked in the visitors parking are left at owners risk.</li>
            <li>The staff and agents of the corporation will not accept responsibility for loss or damage howsoever caused.</li>
            <li>Permit is to be used by visitors and is not to be used by residents.</li>
            <li>Permits must be clearly displayed at all times.</li>
          </ul>
          <strong>VEHICLES REMAINING AFTER PASS HAS EXPIRED MAY BE TOWED AT OWNERS EXPENSE</strong>
        </body>
      </html>
    `
    },
    handleApprovalToggle() {
      console.log('handleApprovalToggle')
      if (this.reportData.approved) {
        this.reportData.approved = false
      } else {
        this.reportData.approved = true
      }
      this.validateForm()
    },
    getResultDisplay(result) {
      const resultOption = this.resultOptions.find(option => option.value === result)
      return resultOption ? resultOption.text : ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
</style>
